.country-select button {
  background: white;
}

[class^="ReactFlagsSelect-module_selectBtn__"]:after,
.reasons-icon {
  content: "" !important;
  display: block !important;
  width: 10px !important; /* Adjust size as needed */
  height: 10px !important;
  border-top: 2px solid #000 !important; /* Or any color */
  border-right: 2px solid #000 !important;
  border-left: 0 !important;
  transform: rotate(135deg);
  margin-right: 5px;
  margin-bottom: 2px;
}
[class^="ReactFlagsSelect-module_selectBtn__"][aria-expanded="true"]:after {
  border-bottom: 0 !important;
  margin-top: 8px;
  transform: rotate(-45deg);
}
.reasons-icon {
  margin-right: 8px !important ;
}
